.meals {
  align-items: center;
  background: #0fa36b;
  color: #464646;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.rocksGlass {
  animation: shake 1s infinite ease;
  max-height: 80vh;
}

/*
#iceLeft {
  animation: clink .8s infinite ease;
  opacity: 0.6;
  transform-origin: 36px 66px;
}

#iceRight {
  animation: clink .8s .2s infinite ease;
  opacity: 0.6;
  transform-origin: 60px 78px;
}
*/

.meals > .logo {
  animation: shake 1.5s infinite ease;
  color: aliceblue;
  font-family: Montserrat , sans-serif;
  font-size: 3em;
  font-weight: 900;
}


@keyframes shake {

  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes clink {

  0% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

.ingredient {
  text-decoration: line-through solid black;
}
